import { Container as CreditasContainer } from '@creditas/layout'
import { css, styled, utilities } from '@creditas/stylitas'

const Container = styled(CreditasContainer)(
  ({ theme }) => css`
    font-weight: 400;
    letter-spacing: 0.3px;
    color: ${utilities.palette.color(theme, 'neutral07')};
    line-height: 22px;
    padding-top: 35px;
    margin-bottom: 35px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      letter-spacing: normal;
      color: ${utilities.palette.color(theme, 'neutral08')};
      margin: 20px 0;
      font-weight: 300;
      text-align: center;
    }

    h1 {
      font-size: 44px;
      line-height: 44px;
    }

    h2 {
      font-size: 38px;
      line-height: 38px;
    }

    h3 {
      font-size: 32px;
      line-height: 32px;
    }

    h4 {
      font-size: 26px;
      line-height: 26px;
    }

    h5 {
      font-size: 20px;
      line-height: 20px;
    }

    h6 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.3px;
      color: ${utilities.palette.color(theme, 'neutral07')};
    }

    p {
      margin: 20px 0;
    }

    b {
      font-weight: 700;
    }

    ul {
      padding-inline-start: 40px;
    }
  `,
)

export { Container }
