import React from 'react'
import { string } from 'prop-types'

import { Container } from './PureText.style'

const propTypes = {
  body: string,
}

const PureText = ({ body, ...others }) => {
  const html = body || ''

  return (
    <Container as="section" maxWidth="lg" data-testid="container" {...others}>
      {/* TODO: Sanitize code */}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  )
}

PureText.propTypes = propTypes

export { PureText }
export default PureText
